@import 'default.scss';
@import 'common.scss';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined');

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
