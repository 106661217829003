.form-field {
  display: block !important;
}
.mat-snack-bar-container {
  color: #ffffff;
  &.success {
    background: #28a745;
  }
  &.error {
    background: #f44336;
  }
  &.warning {
    background: #f59e0b;
  }
  &.info {
    background: #3f51b5;
  }
}
.mat-error,
.mat-hint {
  line-height: 22px;
}
.mat-form-field-subscript-wrapper {
  margin-top: 0;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary
  .mat-radio-ripple
  .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background: #3882fe;
}
.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3882fe;
}
.mat-form-field-appearance-outline {
  .mat-form-field-infix {
    padding: 8px 0;
  }
  .mat-form-field-label-wrapper {
    font-size: 16px;
    top: -22px;
  }
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float
  .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float
  .mat-input-server:focus
  + .mat-form-field-label-wrapper
  .mat-form-field-label {
  transform: translateY(-19px) scale(0.75);
}
.mat-form-field-appearance-outline .mat-form-field-prefix,
.mat-form-field-appearance-outline .mat-form-field-suffix {
  top: 3px !important;
}
.mat-select-value,
.mat-input-element {
  position: relative;
  top: -3px;
}
#loading-page {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  justify-content: center;
  align-items: center;
  z-index: 999;
}
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-thumb {
  background: rgba($color: #000000, $alpha: 0.26);
}
.mat-select-value-text {
  font-size: 14px;
}
.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
.mat-select-disabled .mat-select-value,
.mat-date-range-input-inner[disabled] {
  color: #000000;
  font-size: 14px;
  cursor: not-allowed;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.8);
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  font-size: 14px;
}
.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background: #3882fe;
}
.mat-date-range-input-container,
.mat-form-field-flex {
  font-size: 14px;
}
.mat-button.mat-primary,
.mat-icon-button.mat-primary,
.mat-stroked-button.mat-primary {
  color: #3882fe;
}
.mat-slide-toggle-bar:hover .mat-slide-toggle-persistent-ripple {
  left: 0;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3882fe !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: rgba($color: #3882fe, $alpha: 0.54) !important;
}
.mat-dialog-container {
  width: 450px;
  padding: 16px 24px 50px !important;
  .mat-dialog-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 40px;
    color: #4d5d7d;
    text-transform: uppercase;
    text-align: center;
  }
  .mat-dialog-content {
    font-size: 16px;
    line-height: 24px;
    color: #4d5d7d;
    text-align: center;
  }
  .mat-dialog-actions {
    padding: 25px 0 0;
    justify-content: center;
    button {
      color: #4d5d7d;
      background: #e5e5e5;
      &:nth-child(2) {
        color: #ffffff;
        background: #3882fe;
      }
    }
  }
}
.tox-statusbar__branding {
  display: none;
}

#crud {
  .actions {
    display: flex;
    justify-content: space-between;
    background: #ececec;
    padding: 18px 0;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1;
    .left {
      display: flex;
      align-items: center;
      .btn-back {
        color: #ffffff;
        background: #3882fe;
        border-radius: 3px;
      }
      h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 30px;
        text-transform: uppercase;
        color: #4d5d7d;
        margin-left: 20px;
      }
    }
    .right {
      > * {
        margin-left: 10px;
      }
    }
  }
  .whitebox {
    min-height: calc(100vh - 166px);
    padding: 34px 30px;
    background: #ffffff;
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 30px;
      text-transform: uppercase;
      color: #4d5d7d;
      margin-bottom: 15px;
    }
    .form {
      display: flex;
      flex-wrap: wrap;
      .form-field {
        width: calc(25% - 22.5px);
        margin-right: 30px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &.textarea {
          flex: 1;
          position: relative;
          textarea {
            height: 80px;
            resize: none;
          }
          .label-count {
            font-size: 12px;
            line-height: 22px;
            color: #858585;
            position: absolute;
            right: 10px;
            bottom: 0;
          }
        }
        .mat-form-field {
          width: 100%;
        }
        .label-sp {
          position: relative;
          top: -6px;
          color: #757575;
        }
      }
      .slide-toggle {
        width: 100%;
        font-size: 14px;
      }
      .w-100 {
        width: 100%;
      }
    }
  }
}
